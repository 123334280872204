var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"elevation":"0"}},[_c('v-card-title',{attrs:{"primary-title":""}},[_vm._v(" SERVICIOS A REALIZAR: ")]),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"3"}},[_c('h4',{staticClass:"mb-2"},[_vm._v("ORIGEN")]),_vm._l((_vm.$store.state.pricing.listServices.filter(
            function (v) { return v.codebegend == 'OR'; }
          )),function(service){return _c('v-checkbox',{key:service.id,attrs:{"dense":"","readonly":""},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" "+_vm._s(service.namegroupservice)+" ")]},proxy:true}],null,true),model:{value:(service.status),callback:function ($$v) {_vm.$set(service, "status", $$v)},expression:"service.status"}})})],2),_c('v-col',{attrs:{"cols":"3"}},[_c('h4',{staticClass:"mb-2"},[_vm._v("FLETE")]),_vm._l((_vm.$store.state.pricing.listServices.filter(
            function (v) { return v.codebegend == 'FL'; }
          )),function(service){return _c('v-checkbox',{key:service.id,attrs:{"dense":"","readonly":""},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" "+_vm._s(service.namegroupservice)+" ")]},proxy:true}],null,true),model:{value:(service.status),callback:function ($$v) {_vm.$set(service, "status", $$v)},expression:"service.status"}})})],2),_c('v-col',{attrs:{"cols":"3"}},[_c('h4',{staticClass:"mb-2"},[_vm._v("DESTINO")]),_vm._l((_vm.$store.state.pricing.listServices.filter(
            function (v) { return v.codebegend == 'DE'; }
          )),function(service){return _c('v-checkbox',{key:service.id,attrs:{"dense":"","readonly":""},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" "+_vm._s(service.namegroupservice)+" ")]},proxy:true}],null,true),model:{value:(service.status),callback:function ($$v) {_vm.$set(service, "status", $$v)},expression:"service.status"}})})],2),_c('v-col',{attrs:{"cols":"3"}},[_c('h4',{staticClass:"mb-2"},[_vm._v("OPCIONAL")]),_vm._l((_vm.$store.state.pricing.listServices.filter(
            function (v) { return v.codebegend == 'OP'; }
          )),function(service){return _c('v-checkbox',{key:service.id,attrs:{"dense":"","readonly":""},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" "+_vm._s(service.namegroupservice)+" ")]},proxy:true}],null,true),model:{value:(service.status),callback:function ($$v) {_vm.$set(service, "status", $$v)},expression:"service.status"}})}),_c('v-text-field',{attrs:{"prefix":"$","error-messages":_vm.$store.state.pricing.errorValorMercancia,"label":"Valor de la mercancia","type":"number","step":"0.10","min":"0","placeholder":"Valor de la mercancia...","autocomplete":"off","dense":"","readonly":""},model:{value:(_vm.$store.state.pricing.datosPrincipales.amount),callback:function ($$v) {_vm.$set(_vm.$store.state.pricing.datosPrincipales, "amount", $$v)},expression:"$store.state.pricing.datosPrincipales.amount"}}),(
            (this.$store.state.pricing.listServices.filter(
              function (v) { return v.codegroupservices == 15; }
            ).length > 0
              ? this.$store.state.pricing.listServices.filter(
                  function (v) { return v.codegroupservices == 15; }
                )[0].status
              : false) ||
            (this.$store.state.pricing.listServices.filter(
              function (v) { return v.codegroupservices == 2; }
            ).length > 0
              ? this.$store.state.pricing.listServices.filter(
                  function (v) { return v.codegroupservices == 2; }
                )[0].status
              : false) ||
            (this.$store.state.pricing.listServices.filter(
              function (v) { return v.codegroupservices == 6; }
            ).length > 0
              ? this.$store.state.pricing.listServices.filter(
                  function (v) { return v.codegroupservices == 6; }
                )[0].status
              : false)
          )?_c('v-text-field',{attrs:{"label":"Descripción de carga","placeholder":"Descripción de carga","autocomplete":"off","dense":"","readonly":""},model:{value:(
            _vm.$store.state.pricing.datosPrincipales.descripcioncarga
          ),callback:function ($$v) {_vm.$set(_vm.$store.state.pricing.datosPrincipales, "descripcioncarga", $$v)},expression:"\n            $store.state.pricing.datosPrincipales.descripcioncarga\n          "}}):_vm._e()],2)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }